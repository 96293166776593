import { fetchReticulumAuthenticated } from "./phoenix-utils";
import defaultAvatar from "../assets/models/DefaultAvatar.glb";

const names = [
  "Baers-Pochard",
  "Baikal-Teal",
  "Barrows-Goldeneye",
  "Blue-Billed",
  "Blue-Duck",
  "Blue-Winged",
  "Brown-Teal",
  "Bufflehead",
  "Canvasback",
  "Cape-Shoveler",
  "Chestnut-Teal",
  "Chiloe-Wigeon",
  "Cinnamon-Teal",
  "Comb-Duck",
  "Common-Eider",
  "Common-Goldeneye",
  "Common-Merganser",
  "Common-Pochard",
  "Common-Scoter",
  "Common-Shelduck",
  "Cotton-Pygmy",
  "Crested-Duck",
  "Crested-Shelduck",
  "Eatons-Pintail",
  "Falcated",
  "Ferruginous",
  "Freckled-Duck",
  "Gadwall",
  "Garganey",
  "Greater-Scaup",
  "Green-Pygmy",
  "Grey-Teal",
  "Hardhead",
  "Harlequin",
  "Hartlaubs",
  "Hooded-Merganser",
  "Kelp-Goose",
  "King-Eider",
  "Lake-Duck",
  "Laysan-Duck",
  "Lesser-Scaup",
  "Long-Tailed",
  "Maccoa-Duck",
  "Mallard",
  "Mandarin",
  "Marbled-Teal",
  "Mellers-Duck",
  "Merganser",
  "Northern-Pintail",
  "Orinoco-Goose",
  "Paradise-Shelduck",
  "Plumed-Whistler",
  "Puna-Teal",
  "Pygmy-Goose",
  "Radjah-Shelduck",
  "Red-Billed",
  "Red-Crested",
  "Red-Shoveler",
  "Ring-Necked",
  "Ringed-Teal",
  "Rosy-Billed",
  "Ruddy-Shelduck",
  "Salvadoris-Teal",
  "Scaly-Sided",
  "Shelduck",
  "Shoveler",
  "Silver-Teal",
  "Smew",
  "Spectacled-Eider",
  "Spot-Billed",
  "Spotted-Whistler",
  "Steamerduck",
  "Stellers-Eider",
  "Sunda-Teal",
  "Surf-Scoter",
  "Tufted-Duck",
  "Velvet-Scoter",
  "Wandering-Whistler",
  "Whistling-duck",
  "White-Backed",
  "White-Cheeked",
  "White-Winged",
  "Wigeon",
  "Wood-Duck",
  "Yellow-Billed"
];

const words1 = [
  "Adorable",
  "Agreeable",
  "Alert",
  "Amber",
  "Ambitious",
  "Amused",
  "Azure",
  "Beautiful",
  "Big",
  "Blue",
  "Brass",
  "Brave",
  "Bright",
  "Bronze",
  "Brown",
  "Bubbly",
  "Calm",
  "Capable",
  "Careful",
  "Charming",
  "Cheeky",
  "Cheerful",
  "Chestnut",
  "Clever",
  "Cobalt",
  "Colossal",
  "Confident",
  "Cooperative",
  "Copper",
  "Coral",
  "Courageous",
  "Crazy",
  "Crimson",
  "Cultured",
  "Cyan",
  "Dashing",
  "Dazzling",
  "Debonair",
  "Decisive",
  "Decorous",
  "Delightful",
  "Determined",
  "Diligent",
  "Dynamic",
  "Eager",
  "Elated",
  "Emerald",
  "Eminent",
  "Enchanting",
  "Encouraging",
  "Energetic",
  "Entertaining",
  "Enthusiastic",
  "Excellent",
  "Excited",
  "Exuberant",
  "Fabulous",
  "Faithful",
  "Famous",
  "Fancy",
  "Fantastic",
  "Fearless",
  "Fine",
  "Flaming",
  "Friendly",
  "Funky",
  "Funny",
  "Generous",
  "Gentle",
  "Gifted",
  "Gigantic",
  "Glamorous",
  "Glorious",
  "Golden",
  "Good",
  "Gray",
  "Great",
  "Green",
  "Handsome",
  "Happy",
  "Helpful",
  "Hilarious",
  "Honorable",
  "Huge",
  "Important",
  "Indigo",
  "Industrious",
  "Instinctive",
  "Iron",
  "Ivory",
  "Jade",
  "Jasmine",
  "Jolly",
  "Kind",
  "Large",
  "Likeable",
  "Lilac",
  "Lime",
  "Little",
  "Lively",
  "Lovely",
  "Loving",
  "Lucky",
  "Mad",
  "Magenta",
  "Magnificent",
  "Maroon",
  "Massive",
  "Mauve",
  "Miniature",
  "Modern",
  "Nice",
  "Obedient",
  "Odd",
  "Old-Fashioned",
  "Orange",
  "Peaceful",
  "Peach",
  "Pearl",
  "Perfect",
  "Pink",
  "Placid",
  "Platinum",
  "Pleasant",
  "Plucky",
  "Popular",
  "Powerful",
  "Proud",
  "Puce",
  "Punctual",
  "Purple",
  "Quaint",
  "Quartz",
  "Quiet",
  "Raspberry",
  "Red",
  "Reliable",
  "Relieved",
  "Resolute",
  "Responsible",
  "Reticent",
  "Rich",
  "Ruby",
  "Scarlet",
  "Sedate",
  "Sensitive",
  "Shrewd",
  "Shy",
  "Silly",
  "Silver",
  "Sincere",
  "Skilful",
  "Small",
  "Smiling",
  "Sparkling",
  "Splendid",
  "Steadfast",
  "Successful",
  "Talented",
  "Tall",
  "Tangerine",
  "Teeny",
  "Thankful",
  "Thoughtful",
  "Thrifty",
  "Tiny",
  "Tough",
  "Trustworthy",
  "Unusual",
  "Upbeat",
  "Victorious",
  "Vigorous",
  "Violet",
  "Vivacious",
  "Warm",
  "White",
  "Wise",
  "Witty",
  "Wonderful",
  "Yellow",   
]

const words2 = [
  "Aardvark",
  "Acrobat",
  "Alien",
  "Alligator",
  "Alpaca",
  "Ant",
  "Anteater",
  "Antelope",
  "Ape",
  "Armadillo",
  "Astronaut",
  "Baboon",
  "Badger",
  "Bat",
  "Bear",
  "Beaver",
  "Bee",
  "Bison",
  "Boar",
  "Buffalo",
  "Butterfly",
  "Camel",
  "Caribou",
  "Cat",
  "Caterpillar",
  "Cheetah",
  "Chicken",
  "Chimp",
  "Chinchilla",
  "Cobra",
  "Cockroach",
  "Cormorant",
  "Coyote",
  "Crab",
  "Crane",
  "Crocodile",
  "Crow",
  "Deer",
  "Dinosaur",
  "Dog",
  "Dolphin",
  "Donkey",
  "Dove",
  "Dragon",
  "Dragonfly",
  "Duck",
  "Dugong",
  "Eagle",
  "Echidna",
  "Eel",
  "Eland",
  "Elephant",
  "Elk",
  "Emu",
  "Falcon",
  "Ferret",
  "Finch",
  "Fish",
  "Fly",
  "Fox",
  "Frog",
  "Gazelle",
  "Gerbil",
  "Ghost",
  "Giraffe",
  "Gnat",
  "Gnu",
  "Goat",
  "Goose",
  "Gopher",
  "Gorilla",
  "Grasshopper",
  "Grouse",
  "Guanaco",
  "Guinea pig",
  "Gull",
  "Hamster",
  "Hare",
  "Hawk",
  "Hedgehog",
  "Heron",
  "Hippopotamus",
  "Hornet",
  "Horse",
  "Hummingbird",
  "Hyena",
  "Iguana",
  "Jackal",
  "Jaguar",
  "Jay",
  "Jellyfish",
  "Kangaroo",
  "King",
  "Knight",
  "Koala",
  "Kouprey",
  "Kudu",
  "Lark",
  "Lemur",
  "Leopard",
  "Lion",
  "Lizard",
  "Llama",
  "Lobster",
  "Locust",
  "Loris",
  "Lyrebird",
  "Magpie",
  "Mallard",
  "Manatee",
  "Meerkat",
  "Mink",
  "Mole",
  "Monkey",
  "Monster",
  "Moose",
  "Mosquito",
  "Mouse",
  "Mule",
  "Narwhal",
  "Newt",
  "Nightingale",
  "Ninja",
  "Ogre",
  "Okapi",
  "Opossum",
  "Oryx",
  "Ostrich",
  "Otter",
  "Owl",
  "Ox",
  "Oyster",
  "Panda",
  "Panther",
  "Parrot",
  "Partridge",
  "Peafowl",
  "Pelican",
  "Penguin",
  "Pig",
  "Pigeon",
  "Pirate",
  "Platypus",
  "Pony",
  "Porcupine",
  "Porpoise",
  "Prairie Dog",
  "Prince",
  "Princess",
  "Queen",
  "Quelea",
  "Rabbit",
  "Raccoon",
  "Ram",
  "Rat",
  "Raven",
  "Reindeer",
  "Rhinoceros",
  "Robot",
  "Rook",
  "Salamander",
  "Sea lion",
  "Sea Urchin",
  "Seahorse",
  "Seal",
  "Seastar",
  "Serval",
  "Shark",
  "Sheep",
  "Shrew",
  "Skeleton",
  "Skunk",
  "Snail",
  "Snake",
  "Spider",
  "Squid",
  "Squirrel",
  "Stinkbug",
  "Swallow",
  "Swan",
  "Tapir",
  "Tarsier",
  "Termite",
  "Tiger",
  "Toad",
  "Trout",
  "Turkey",
  "Turtle",
  "Vampire",
  "Vicuña",
  "Wallaby",
  "Walrus",
  "Wasp",
  "Water Buffalo",
  "Weasel",
  "Whale",
  "Wolf",
  "Wombat",
  "Woodpecker",
  "Worm",
  "Wren",
  "Yak",
  "Zebra",  
]

function chooseRandom(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function generateRandomName() {
  return `${chooseRandom(words1)} ${chooseRandom(words2)}`;
}

export function fetchDefaultAvatarId() {
  return new URL(defaultAvatar, location.href).href;
}

export async function fetchRandomDefaultAvatarId() {
  const defaultAvatarEndpoint = "/api/v1/media/search?filter=default&source=avatar_listings";
  const defaultAvatars = (await fetchReticulumAuthenticated(defaultAvatarEndpoint)).entries || [];
  if (defaultAvatars.length === 0) {
    // If reticulum doesn't return any default avatars, just default to the duck model. This should only happen
    // when running against a fresh reticulum server, e.g. a local ret instance.
    return new URL(defaultAvatar, location.href).href;
  }
  const avatarIds = defaultAvatars.map(avatar => avatar.id);
  return chooseRandom(avatarIds);
}
